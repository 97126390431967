//Register.jsx
import '../css/from.css';
import React, { useState, useEffect } from 'react';
import {Splash} from '../public/Splash.jsx';
import { Link } from 'react-router-dom';

export function Register(){
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulasikan waktu pemuatan
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Ubah angka ini sesuai kebutuhan
  
      // Hapus timeout jika komponen dibongkar
      return () => clearTimeout();
    }, []);
    return (
      <>
      {loading ? <Splash /> : <MainRegister />}
      </>
    )
}

function MainRegister () {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
      const [successMessage, setSuccessMessage] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Kirim data form
          const response = await fetch('submit.php', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            setSuccessMessage('Pesan telah terkirim!');
            setFormData({
              name: '',
              email: '',
              message: ''
            });
          } else {
            setErrorMessage('Terjadi kesalahan. Silakan coba lagi.');
          }
        } catch (error) {
          console.error('Error:', error);
          setErrorMessage('Terjadi kesalahan. Silakan coba lagi.');
        }
    };
    
      return (
        <>
        <div className='container'>
            <div className="form-container">
            <h1>Register</h1>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Email:</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            
                    <label htmlFor="email">Password:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            
                    <button type="submit" >Register</button>
                </form>
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div style={{marginTop:"100px"}}>
                    <div style={{float:"left"}}>
                        <Link to={"/"}>home</Link>
                    </div>
                    <div style={{float:"right"}}>
                        <Link to={"/login"}>Login</Link>
                    </div>
                </div>            
            </div>

        </div>
        </>

      );
}