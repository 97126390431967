//Navbar.jsx
import { Link } from "react-router-dom";
import '../css/Navbar.css';

export function Navbar() {
    return (
        <div className="">
            <div className="kepala">
                <div className="navbar">
                    <div className="kiri">
                    <Link to="/">Home</Link> 
                    </div>
                    
                    <div className="kanan">
                        <Link to="/user">chat</Link>
                        <Link to="/null">About</Link>
                        <Link to="/null">Services</Link>
                        <Link to="/login">Wallet</Link>
                    </div>
                </div>

            </div>            
        </div>

    );
}
