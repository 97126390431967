// UsersLoad.jsx
import React, { useState, useEffect } from 'react';
import {Splash} from './Splash';
import '../css/Chat.css'

export function UsersLoad(){
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
    return () => clearTimeout();
  }, []);
  return (
    <>
    {loading ? <Splash /> : <Users />}
    </>
  )
}

function Users() {

  return (
    <>
        {/* info on off */}
        <div className='chat_box'>
            <div className='kurung_notif_online'>
                <div className='lamp_aktif'>
                </div>
                <span>online 10</span>
            </div>
            <div className='kurung_notif_offline'>
                <div className='lamp_offline'>
                </div>
                <span>offline 10000</span>
            </div>
        </div>

        {/* chat */}
        <div className='chat_box_ui'>
            <span className='Server'><span className='admin'>admin :</span> Asalamu alaikum? apa semuanya apa baik baik saja ? <span className='time'>10:10</span></span>
            <span className='Client'><span className='admin'>unknow :</span>Wa alaikumu salam baik<span className='time'>10:10</span></span>
            <span className='Client'><span className='admin'>unknow :</span>wa alikum salam alhamdulilah baik<span className='time'>10:10</span></span>
        </div>

        {/* input dan tombol */}
        <div className='chat_box'>
            <input
            style={{
                borderRadius:'10px'
                
            }}
            type="text" name="" id="" />

            <span
            style={{
                marginLeft:'10px',padding:'5px',background:'black',
                borderRadius:'10px',cursor:'pointer'
            }}
            >SEND</span>
        </div>
    </>    
    
  );
}
